import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "author": "Noah Wong",
  "date": "2020-06-09 UTC -8",
  "length": 1,
  "location": "Portland, Oregon",
  "path": "/uses-tech/",
  "title": "List of Everyday Hardware and Software",
  "tags": ["tech", "hardware", "software"],
  "description": "This is the hardware and software that I am using today."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I decided to join the growing number of folks who are sharing lists of the hardware and software I use everyday. This list changes frequently but this is what I am using today. `}</p>
    <h5 style={{
      "margin": "3rem 0 0 0"
    }}>Development Tools</h5>
    <ul style={{
      "marginTop": "0"
    }} className="custom double-arrow">
      <li><b>Text Editor:</b> VS Code</li>
      <li><b>Terminal Emulator:</b> iTerm2</li>
    </ul>
    <h5 style={{
      "margin": "3rem 0 0 0"
    }}>Productivity Tools</h5>
    <ul style={{
      "marginTop": "0"
    }} className="custom double-arrow">
      <li><b>All-in-one-tool:</b> Notion</li>
      <li><b>Email:</b> Fastmail</li>
    </ul>
    <h5 style={{
      "margin": "3rem 0 0 0"
    }}>Design Tools</h5>
    <ul style={{
      "marginTop": "0"
    }} className="custom double-arrow">
      <li><b>Design Software:</b> Figma</li>
    </ul>
    <h5 style={{
      "margin": "3rem 0 0 0"
    }}>Various Software</h5>
    <ul style={{
      "marginTop": "0"
    }} className="custom double-arrow">
      <li><b>macOS Productivity app:</b> Alfred</li>
      <li><b>Password Manager:</b> 1Password</li>
      <li><b>Browser:</b> Brave</li>
      <li><b>Video Editing:</b> DaVinci Resolve</li>
    </ul>
    <h5 style={{
      "margin": "3rem 0 0 0"
    }}>Hardware</h5>
    <ul style={{
      "marginTop": "0"
    }} className="custom double-arrow">
    <li><b>Computer:</b> MacBook Pro (16-in, 2019) (2.3 GHz 8-Core Intel Core i9, 16 GB 2667 MHz DDR4)</li>
    <li><b>Keyboard:</b> Azio USB Mechanical Backlit Keyboard for Mac (Brown K-Switch)</li>
    <li><b>Mouse:</b> MX Master 3</li>
    <li><b>Storage:</b> Synology 2 bay NAS DiskStation DS218j (Diskless)</li>
    <li><b>Wireless Headphones:</b> Sony WH-1000XM4</li>
    <li><b>Wireless Earbuds:</b> Jabra Elite 65t</li>
    <li><b>Phone:</b> OnePlus 7T</li>
    <li><b>WiFi:</b> TP-Link Deco Mesh WiFi System</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      